import React from "react"
import Layout from '../components/layout'
import {graphql, useStaticQuery} from 'gatsby'
import Img from "gatsby-image"
import styles from './inspiration.module.scss'
import SEO from "../components/seo"

const GalleryPage = () => {

  const data = useStaticQuery(graphql`
  query{
    allContentfulGalleryImages{
      edges{
        node{
          title
          image{
            file{
              url
            }
            fluid(maxWidth:1000){
              ...GatsbyContentfulFluid_withWebp
            }
            localFile{
              absolutePath
              publicURL
              childImageSharp{
                fluid(maxWidth:1000){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
  `)


  return (
    <Layout>
      <SEO title="Inspiration" keywords={[ `reflect`, `simple things`, `bullet journal`, `images`, `gallery`, `inspiration`]}/>
      <div className={styles.imageGrid}>
        {data.allContentfulGalleryImages.edges.map((edge) => {
          return (
            <div className={styles.imageContainer}>      
              <Img fluid={edge.node.image.localFile.childImageSharp.fluid} alt=""/>
            </div>
          )
        })}
      </div>
    </Layout>  
  )
}

export default GalleryPage;